<template>
<div class="main">
  <VxeOrgTree service="/sys/sysOrganization/treeQueryAuthCar/parkspace" class="main_left" />
  <div class="main_right">
    <VxeBasicTable ref="xGrid" :gridOptions="gridOptions" :formOptions="formOptions" :searchOptions="searchOptions" :defaultTrigger="false">
    </VxeBasicTable>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      selectRow: {},
      currNode: {},
      gridOptions: {
        // 列表功能按钮组
        toolbarConfig: {
          buttons: [{
              code: "create",
              name: "新增",
              status: "primary",
              events: {
                click: () => {
                  let { items, data } = this.formOptions;
                  this.$Tools.enabledEvent(items, ["parkingSpaceType", "parkSpaceStatus", "parkingSpacePayType"], false);
                  this.selectNode(data, this.currNode, "created");
                }
              }
            },
            { code: "batchDelete", name: "批量删除", status: "danger" },
            {
              code: "generated",
              name: "生成费用",
              status: "primary",
              events: {
                click: () => {
                  this.generateCostEvent(this.currNode);
                }
              }
            }
          ],
          refresh: {
            query: () => {
              this.$refs.xGrid.getLists();
            },
          },
        },
        // 列表字段配置
        columns: [{
            type: "seq",
            width: 60,
          },
          {
            type: "checkbox",
            width: 60,
          },
          {
            field: "parkingSpaceName",
            title: "车位号",
            minWidth: 120,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: "请输入车位号",
              },
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            minWidth: 160,
            editRender: {
              name: "$select",
              enabled: false,
              optionTypeCode: "parkType",
              props: {
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "configName",
            title: "收费项",
            minWidth: 200,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                placeholder: "请选择收费项",
              },
            },
          },
          {
            field: "startTime",
            title: "起始时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "month",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择起始时间",
              }
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            minWidth: 160,
            editRender: {
              name: "$input",
              props: {
                type: "month",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择结束时间",
              }
            },
          },
          {
            field: "chargeableTime",
            title: "截止日期",
            minWidth: 160,
            editRender: {
              enabled: false,
              name: "$input",
              props: {
                type: "month",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                placeholder: "请选择截止日期",
              }
            },
          },
          {
            field: "parkSpaceStatus",
            title: "车位状态",
            minWidth: 160,
            editRender: {
              enabled: false,
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                placeholder: "请选择车位状态",
              },
            },
          },
          {
            fixed: "right",
            title: "操作",
            width: 160,
            editRender: {
              name: "$VxeGridButtons",
              lists: [{
                  status: "primary",
                  label: "修改",
                  name: 'edit',
                  eventName: "queryEvent",
                  events: {
                    click: () => {
                      let { items } = this.formOptions;
                      this.$Tools.enabledEvent(items, ["parkingSpaceType", "parkSpaceStatus", "parkingSpacePayType"], true);
                    }
                  }
                },
                {
                  status: "danger",
                  label: "删除",
                  name: "delete",
                  eventName: "removeEvent",
                },
              ],
            },
          },
        ],
        editConfig: {},
        exportConfig: {},
        importConfig: {},
      },
      formOptions: {
        // 新增功能字段配置
        width: 800,
        titleWidth: 110,
        items: [{
            field: "parentId",
            title: "所属上级",
            span: 24,
            itemRender: {
              name: "$VxeSelectTree",
              expandAll: true,
              props: {
                placeholder: "请选择所属上级",
              },
              events: {
                click: (params, node) => {
                  let { data } = params;
                  this.selectNode(data, node, "edit");
                },
              }
            },
          },
          {
            field: "configId",
            title: "收费项",
            span: 12,
            itemRender: {
              name: "$VxeSelectList",
              service: "/fee/fPayFeeConfig",
              defaultProps: {
                label: "feeName",
                value: "id",
              },
              defaultParams: {
                parentId: "",
                feeFlag: "1",
                chargeType: "2",
              },
              props: {
                placeholder: "请选择收费项",
              },
              events: {
                change: (params, node) => {
                  let { data, property } = params;
                  data[property] = node.id;
                  data["configName"] = node.feeName;
                }
              }
            },
          },
          {
            field: "parkingSpaceType",
            title: "车位类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkType",
              props: {
                placeholder: "请选择车位类型",
              },
            },
          },
          {
            field: "parkSpaceStatus",
            title: "车位状态",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "parkStatus",
              props: {
                multiple: true,
                placeholder: "请选择房屋状态(可多选)",
              },
            },
          },
          {
            field: "parkingSpacePayType",
            title: "缴费类型",
            span: 12,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentType",
              props: {
                maxlength: 24,
                placeholder: "请选择缴费类型",
              },
            },
          },
          {
            field: "startTime",
            title: "起始时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择起始时间",
              },
            },
          },
          {
            field: "endTime",
            title: "结束时间",
            span: 12,
            itemRender: {
              name: "$input",
              props: {
                type: "month",
                labelFormat: "yyyy-MM",
                placeholder: "请选择起始时间",
              },
            },
          },
          {
            field: "payStatus",
            title: "缴费状态",
            span: 12,
            visible: false,
            itemRender: {
              name: "$select",
              optionTypeCode: "paymentStatus",
              props: {
                placeholder: "请选择缴费状态",
              },
            },
          },
          {
            field: 'remark',
            title: '描述',
            span: 24,
            itemRender: {
              name: '$textarea',
              props: {
                showWordCount: true,
                maxlength: "300",
                resize: "none",
                placeholder: '请输入描述内容'
              }
            }
          },
          {
            align: "center",
            span: 24,
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "提交",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "取消",
                  },
                },
              ],
            },
          },
        ],
        //表单校验规则
        rules: {
          parentId: [{ required: true, message: "请选择所属上级" }],
          configId: [{ required: true, message: "请选择收费项" }],
          parkingSpaceType: [{ required: true, message: "请选择车位类型" }],
          parkSpaceStatus: [
            { required: true, message: "请选择车位状态" },
            {
              validator: ({ itemValue, rules, data }) => {
                if (itemValue && !itemValue.length) {
                  return new Error(`请选择车位状态`);
                }
              },
            }
          ],
          parkingSpacePayType: [{ required: true, message: "请选择缴费类型" }],
          startTime: [{ required: true, message: "请选择开始时间" }],
          endTime: [{ required: true, message: "请选择结束时间" }],
        },
        // 新增功能字段初始化
        data: {
          parentId: "",
          type: "",
          configId: '',
          roomType: "",
          parkingSpaceType: "1",
          parkSpaceStatus: ['1'],
          payerObjType: 'car',
          parkingSpacePayType: "",
          startTime: "",
          endTime: "",
          payStatus: "1",
        },
      },
      // 查询功能字段配置
      searchOptions: {
        items: [{
            field: "parkingSpaceName",
            title: "车位编号",
            itemRender: {
              name: "$input",
              props: {
                placeholder: "请输入车位编号",
              },
            },
          },
          {
            align: "center",
            itemRender: {
              name: "$buttons",
              children: [{
                  props: {
                    type: "submit",
                    content: "查询",
                    status: "primary",
                  },
                },
                {
                  props: {
                    type: "reset",
                    content: "重置",
                  },
                },
              ],
            },
          },
        ],
        data: {
          payerObjType: 'car',
          parkingSpaceName: '',
        },
      },
    };
  },
  methods: {
    ...mapActions(['findByEventService', 'submitEventService']),

    handleNodeClick(treeData, node) {
      this.currNode = node;
      this.$Tools.buildDataToGrid(this.formOptions.items, treeData, "parentId");
      this.formOptions.data['parentId'] = node.id;
      this.formOptions.data['type'] = node.type;
      let { data } = this.searchOptions;
      data['communityId'] = node.communityId;
      data['parentId'] = node.id;
      data["type"] = node.type;
      this.$refs.xGrid.getLists();
      this.getFeeConfigLists(node);
    },

    // 获取费用项
    getFeeConfigLists(node) {
      this.findByEventService({
          service: "/fee/fPayFeeConfig",
          params: {
            parentId: node.communityId || node.id
          }
        })
        .then(({ code, data }) => {
          if (code == 200) {
            this.$Tools.buildDataToGrid(this.gridOptions.columns, data, "configId", { value: "id", label: "feeName" });
            this.$Tools.buildDataToGrid(this.formOptions.items, data, "configId", { value: "id", label: "feeName" });
          }
        });
    },

    selectNode(params, node, type) {
      let typeList = ['1001', '2001', '2002']
      if (typeList.indexOf(node.type) > -1) {
        this.formOptions.items[1].itemRender.defaultParams["parentId"] = node.communityId || node.id;
        params["configId"] = "";
        params["parentId"] = node.id;
        params["type"] = node.type;
        params["roomType"] = node.feeTypeId;
        if (type == "created") {
          this.$refs.xGrid.insertEvent();
        }
      } else {
        this.formOptions.items[1].itemRender.defaultParams["parentId"] = "";
        this.$message.error('请选择小区、停车场、车位')
      }
    },

    // 生成费用
    generateCostEvent(node) {
      let typeList = ['1001', '2001', '2002']
      if (typeList.indexOf(node.type) > -1) {
        let content = `是否对【${node.name}】批量生成费用?`
        this.$confirm(content, this.$t("confirmTitle")).then((type) => {
          if (type === "confirm") {
            if (this.loading) {
              return;
            }
            this.loading = true;
            this.submitEventService({
              service: "/fee/generateFee/generateAllFee",
              params: {
                type: "car",
                payType: node.type,
                parentId: node.id,
                communityId: node.communityId,
              }
            }).then(({ code }) => {
              if (code == 200) {
                this.$message.success(`【${node.name}】费用生成成功`)
              }
              this.loading = false;
            })
          }
        });
      } else {
        this.$message.error('请选择小区、停车场、车位')
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
